import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import moment from 'moment';
import 'slick-carousel';
import Carousel3d from "./carousel3d";
import './load-casinos';
import './casino-review';
import './search-suggestions';
import './big-wins';
import './twitch';
import './mega-menu';
import './tabs';
import './sliders';
import './christmas-calendar';
import './played-casino';
import './theme-switch';
import './popups';

$(document).ready(function($){

  var triggered_times = 0;

  $(window).on('scroll', function() {
      var y_scroll_pos = window.pageYOffset;
      var scroll_pos_test = 150;   // set to whatever you want it to be

      if(y_scroll_pos > scroll_pos_test && triggered_times == 0 ) {
        //alert('This alert is triggered after you have scroll down to 150px')
        $('#casino-cta').show().addClass('animate').addClass('anim-fadein');
        triggered_times = 1;   // to make sure the above action triggers only once
      }
  });

})

/*
$(document).on('click', '.page-header .section-desc-toggle', function () {
  $(this).toggleClass('active');
  $(this).prev('.section-desc').toggleClass('active')
})
*/


let allCurrentCasinos = document.querySelectorAll('.js-amount-show-posts');

$(document).on('click', '.casino-list-section .load-more', function () {
  let moreBtn = $(this);
  let block = moreBtn.closest('.casino-list-section')
  let filterByTags = block.data('filter-by-tags');
  let tagsFilteringLogic = block.data('tags-filtering-logic');
  let filterByTerm = block.data('filter-by-term');
  let filterByTermId = block.data('filter-by-term-id');
  let sortBy = block.data('sort-by');
  let topCasinos = block.data('top-casinos');
  let region = block.data('region');

  let userSort = block.find('.casino-list-filters .btn.active').data('user-sort');
  let userSearchTerm = block.find('.casino-list-filters input[type="text"]').val();
  let userFilterByTag = block.find('.casino-list-filters .dropdown .dropdown-item.active').data('id');
  let layoutStyle = block.data('layout-style');
  let bonusDisplay = block.data('bonus-display');

  let data = {
    action: block.data('action'),
    block_id: block.data('block-id'),
    number_casinos: block.data('number-casinos'),
    page: moreBtn.data('current-page') + 1
  };

  if (userSearchTerm) {
    data.user_search_term = userSearchTerm;
  }

  if (userFilterByTag) {
    data.user_filter_by_tag = userFilterByTag;
  }

  if (userSort) {
    data.user_sort_by = userSort;
  }

  if (filterByTags) {
    data.filter_by_tags = filterByTags;
    data.tags_filtering_logic = tagsFilteringLogic;
  }

  if (sortBy) {
    data.sort_by = sortBy;
  }

  if (topCasinos) {
    data.top_casinos = topCasinos;
  }

  if (region) {
    data.region = region;
  }

  if (filterByTerm) {
    data.filter_by_term = filterByTerm;
  }

  if (filterByTermId) {
    data.filter_by_term_id = filterByTermId;
  }

  if (layoutStyle) {
    data.layout_style = layoutStyle;
  }

  if(bonusDisplay) {
    data.bonus_display = bonusDisplay;
  }

  $.ajax({
    url: theme_ajax.url,
    data: data,
    type: 'POST',
    beforeSend: function () {
      moreBtn.prop('disabled', true);
    },
    complete: function () {
      moreBtn.prop('disabled', false);
    },
    success: function (response) {

      if (response.templates) {

        moreBtn.prev().append(response.templates);
        moreBtn.data('current-page', data.page);

      } else {
        moreBtn.hide();
      }

      if (response.amount) {
        let count_post = block[0].querySelectorAll('.js-amount-show-posts');

        if (count_post.length >= response.amount) {
          moreBtn.hide();
        }
      }
    },
    error: function (xhr, ajaxOptions, thrownError) {
      alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
    }
  });
})

$(document).on('click', '.casino-list-filters .btn[data-user-sort]', function () {
  let sortBtn = $(this)
  sortBtn.parent().find('.btn[data-user-sort]').removeClass('active');
  sortBtn.addClass('active');

  sortBtn.closest('.casino-list-filters').submit()
});

$(document).ready(function() {
  // Function to close all dropdowns
  function closeAllDropdowns() {
      $('.casino-list-filters .dropdown-menu').removeClass('show');
  }

  // Toggle dropdown on click
  $(document).on('click', '.casino-list-filters .dropdown-toggle', function(e) {
      e.stopPropagation();
      let dropToggle = $(this);
      let dropMenu = dropToggle.parent().find('.dropdown-menu');

      // Close all other dropdowns
      $('.casino-list-filters .dropdown-menu').not(dropMenu).removeClass('show');

      // Toggle current dropdown
      dropMenu.toggleClass('show');
  });

  // Close dropdown when clicking on a dropdown item
  $(document).on('click', '.casino-list-filters .dropdown-menu .dropdown-item', function(e) {
      e.stopPropagation();
      let btn = $(this);
      btn.closest('.dropdown').find('.dropdown-toggle').text(btn.text());
      btn.parent().find('.dropdown-item').removeClass('active');
      btn.addClass('active');

      closeAllDropdowns();
      btn.closest('.casino-list-filters').submit();
  });

  // Close dropdown when clicking outside
  $(document).on('click', function(e) {
      if (!$(e.target).closest('.casino-list-filters .dropdown').length) {
          closeAllDropdowns();
      }
  });
});

$(document).on('click', '.casino-list-filters .dropdown .dropdown-menu .dropdown-item', function (e) {
  let btn = $(this)
  btn.closest('.dropdown').find('.dropdown-toggle').text(btn.text())
  btn.parent().find('.dropdown-item').removeClass('active');
  btn.addClass('active');

  btn.closest('.casino-list-filters').submit()
});
let block_update_casino_unicue = document.querySelectorAll('.casino-list-section');
(block_update_casino_unicue) && block_update_casino_unicue.forEach(block => {
  if (block) {
    let items = block.querySelectorAll('.js-amount-show-posts');
    let tmp_array_new_exclude_casino = [];

    (items) && items.forEach(new_item_casino => {
      tmp_array_new_exclude_casino.push(new_item_casino.dataset.id);
    });

    block.dataset.topCasinos = tmp_array_new_exclude_casino.join();
  }
});

/* On filtering */
$(document).on('submit', '.casino-list-filters', function (e) {
  e.preventDefault();
  let form = $(this);
  let block = form.closest('.casino-list-section');
  let sortBtn = form.find('.btn.active[data-user-sort]')
  let filterByTags = block.data('filter-by-tags');
  //let filterByUserTags = form.find('.user-tag-filter').html();
  //filterByTags.push(filterByUserTags);
  ////alert(filterByUserTags);
  let tagsFilteringLogic = block.data('tags-filtering-logic');
  let filterByTerm = block.data('filter-by-term');
  let filterByTermId = block.data('filter-by-term-id');
  let sortBy = block.data('sort-by');
  let region = block.data('region');
  let layoutStyle = block.data('layout-style');
  let userSearchTerm = form.find('input[type="text"]').val();
  let userFilterByTag = form.find('.dropdown .dropdown-item.active').data('id');
  //alert(userFilterByTag);

  let data = {
    action: block.data('action'),
    block_id: block.data('block-id'),
    number_casinos: block.data('number-casinos'),
    page: 0
  };

  if (userSearchTerm) {
    data.user_search_term = userSearchTerm;
    block[0].dataset.topCasinos = '';
  }

  if (userFilterByTag) {
    data.user_filter_by_tag = userFilterByTag;
  }

  if (sortBtn.length) {
    data.user_sort_by = sortBtn.data('user-sort')
  }

  if (filterByTags) {
    data.filter_by_tags = filterByTags
    data.tags_filtering_logic = tagsFilteringLogic
  }

  if (sortBy) {
    data.sort_by = sortBy
  }

  if (region) {
    data.region = region
  } else {
    data.region = "global"
  }

  if (filterByTerm) {
    data.filter_by_term = filterByTerm;
  }

  if (filterByTermId) {
    data.filter_by_term_id = filterByTermId;
  }

  if (layoutStyle) {
    data.layout_style = layoutStyle;
  }

  $.ajax({
    url: theme_ajax.url,
    data: data,
    type: 'POST',
    beforeSend: function () {
    },
    complete: function () {
    },
    success: function (response) {
      block.find('.items').html(response.templates);

      if (block[0]) {
        let items = block[0].querySelectorAll('.js-amount-show-posts');
        let tmp_array_new_exclude_casino = [];

        (items) && items.forEach(new_item_casino => {
          tmp_array_new_exclude_casino.push(new_item_casino.dataset.id);
        });

        block[0].dataset.topCasinos = tmp_array_new_exclude_casino.join();
      }
      let loadMoreBtn = block.find('.load-more');

      if (response.templates) {
        loadMoreBtn.data('current-page', data.page).show()
      } else {
        loadMoreBtn.hide();
      }

      if (response.amount) {
        let count_post = block[0].querySelectorAll('.js-amount-show-posts');

        if (count_post.length >= response.amount) {
          loadMoreBtn.hide();
        }
      }
      console.log(data);
    },
    error: function (xhr, ajaxOptions, thrownError) {
      alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
    }
  });
});


$(document).on("click", "#back-to-top", function (e) {
  e.preventDefault();
  $('html').stop().animate({scrollTop: 0}, "slow");
  return false;
});

$(document).ready(function () {
  const BUBBLE_NAV = document.querySelector('.js-bubble-nav');
  const BUBBLE_BTN = document.querySelectorAll('.js-bubble-btn');
  const IS_CASINO = document.querySelector('.single-casino');

  if (BUBBLE_NAV && BUBBLE_BTN && !IS_CASINO) {

    /*
    init_flugs_menu(BUBBLE_NAV, BUBBLE_BTN);

    BUBBLE_BTN.forEach(item => {
      item.addEventListener('click', btn => {
        BUBBLE_NAV.classList.toggle('open');
      })
    });
    */

    //I'm using "click" but it works with any event
    document.addEventListener('click', function (event) {
      if (!event.target.classList.contains('js-bubble-btn')) {
        let isClickInside = BUBBLE_NAV.contains(event.target);
        if (!isClickInside && BUBBLE_NAV.classList.contains('open')) {
          BUBBLE_NAV.classList.remove('open');
        }
      }
    });

    let last_known_scroll_position = 0;
    let wrapper = ""; //document.querySelector('.js-bubble-wrap');
    function fixed_position(remove) {
      if (remove) {
        wrapper.classList.remove('fixed');
      } else {
        wrapper.classList.add('fixed');
      }
    }

    window.addEventListener('scroll', function (e) {
      last_known_scroll_position = window.scrollY;
      if (wrapper) {
        if (last_known_scroll_position > 100) {
          fixed_position(false);
        } else {
          fixed_position(true)
        }
      }

      if (last_known_scroll_position > 150) {
        document.body.classList.add('scroll-site');
      } else {
        document.body.classList.remove('scroll-site');
      }
    });
  }

  const MEGA_MENU = document.querySelector('.js-mega-menu');
  const ITEMS_MEGA_MENU = document.querySelectorAll('.js-mega-menu > .menu-item > a');
  const SEARCH_DROPDOWN = document.querySelector('.search-suggestions');

  $('[data-bs-toggle="mobilemenu"]').on('click', function () {
    $(this).toggleClass('collapsed');
    $('.navbar-collapse').toggleClass('open');
    $('body').toggleClass('menu-open');
    close_search_dropdown();
  });
  let padding_mouse_over = true;
  offset_mega_menu(padding_mouse_over);
  (ITEMS_MEGA_MENU) && ITEMS_MEGA_MENU.forEach(link => {
    link.addEventListener('mouseover', el => {
      MEGA_MENU.classList.add('open-mega-menu');
      close_search_dropdown();
      offset_mega_menu();
    });
  });

  (MEGA_MENU) && MEGA_MENU.addEventListener('mouseleave', el => {
    MEGA_MENU.classList.remove('open-mega-menu');
    offset_mega_menu();
  });

  const anchors = document.querySelectorAll('a[href*="#"]');

  for (let anchor of anchors) {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      const blockID = anchor.getAttribute('href').substr(1);
      if (blockID) {
        let el_tmp = document.querySelector('#' + blockID);
        el_tmp.style.position = 'relative';
        el_tmp.style.top = '-100px';
        el_tmp.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
        el_tmp.style.top = '0';
      }
    })
  }

  window.addEventListener("resize", offset_mega_menu, false);

  function offset_mega_menu($padding = false) {
    if (ITEMS_MEGA_MENU && window.innerWidth > 1090) {
      const LAST_ITEM_MENU = ITEMS_MEGA_MENU[ITEMS_MEGA_MENU.length - 1];
      let li_tmp = LAST_ITEM_MENU.closest('li');
      let sub_tmp = li_tmp.querySelector('.sub-menu');
      if (sub_tmp) {
        sub_tmp.style.transition = 'none';
        sub_tmp.style.left = (LAST_ITEM_MENU.offsetLeft - 20) + 'px';
      }
    }
  }

  function close_search_dropdown() {
    if (SEARCH_DROPDOWN) {
      SEARCH_DROPDOWN.style.display = "none";
    }
  }

  function init_flugs_menu() {
    const flugs_container = BUBBLE_NAV.querySelector('.js-bubble-flags ul');
    const select_flugs = document.querySelectorAll('.js-flag-change');
    const select_headers = document.querySelectorAll('h2');
    const filtered_header_list = []
    select_headers.forEach(header => {
      let element = header
      while (true) {
        element = element.parentElement
        if (element.id.substring(0, 7) == "section") {
          filtered_header_list.push({icon: "icon-info", label: header.textContent, link: element.id})
          break
        }
        if (element.tagName == "SECTION" && !filtered_header_list.includes(element)) {
          filtered_header_list.push({icon: element.dataset.icon, link: element.getAttribute('id'), label: element.dataset.label})
          break
        }
      }

    })
    let template = '';
    (filtered_header_list) && filtered_header_list.forEach(item => {
      //console.log(section.dataset.label, section.getAttribute('id'));
      template += `<li class="${item.icon}"><a href="#${item.link}">${item.label}</a></li>`;
    });

    if (template !== '') {
      flugs_container.innerHTML = template;
      BUBBLE_BTN[0].classList.add('show');
    }
  }

  const container = document.querySelector('.js-text-container');

  if (container) {
    let h = container.clientHeight;
    let font_size = 20;

    if (h && font_size) {
      height_minus(h, font_size);
    }
  }

  function height_minus(h, font_size) {

    let text = document.querySelector('.js-text');
    if (!text) return false;
    if (font_size < 8) return font_size;
    if (h > (text.clientHeight)) {
      return font_size;
    } else {
      text.style.fontSize = font_size + 'px';
      return font_size = height_minus(h, font_size -= 1);
    }
  }

  const debounce = (fn, time = 1000) => {

    if (!fn) {
      throw Error('"debounce function - "You didn\'t add required parameters');
    }

    let timeout;

    return function () {
      const functionCall = () => fn.apply(this, arguments);

      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    }
  };

  const search = document.querySelectorAll('.js-form-search-header input');
  const search_clear = document.querySelectorAll('.js-form-search-header button');
  const html = document.querySelector('html');
  const lang = html.getAttribute('lang');
  const fi_text = 'Start typing and get search results instantly';
  const en_text = 'Start typing and get search results instantly';
  const en_no_found_text = 'Not found';
  const fi_no_found_text = 'Not found';
  console.log(lang);

  (search_clear) && search_clear.forEach(el => {
    el.addEventListener('click', event => {
      event.preventDefault();
      let form = event.target.closest('form');
      let search_result = form.querySelector('.js-dropdown-search-result');
      search_result.innerHTML = '<p>' + (lang === 'fi') ? fi_text : en_text + '</p>';
      form.querySelector('input').value = '';
    })
  });

  const searchFn = debounce((event) => {
    let value = event.target.value;
    let action = 'vihjeareena_search_casinos_ajax';
    let form = event.target.closest('form');
    let search_result = form.querySelector('.js-dropdown-search-result');

    if (value.length === 0) {
      search_result.innerHTML = '<p>' + (lang === 'fi') ? fi_text : en_text + '</p>';
    } else {
      search_result.innerHTML = '';
    }
    if (form) {
      let form_data = new FormData(form);
      form_data.append('action', action);

      const options = {
        method: 'POST',
        body: form_data,
      };

      fetch(theme_ajax.url, options)
        .then(res => res.json())
        .then(requestStatus => {
          if (requestStatus.length) {

            console.log(requestStatus);
            console.log(requestStatus.length);

            let result = '';
            if (requestStatus.length) {
              requestStatus.forEach(el => {
                let template = '';
                if (!el.image) {
                  template = `<a href="${el.link}">${el.name}</a>`;
                } else {
                  template = `<a href="${el.link}">${el.name}<img src="${el.image}" alt="icon"></a>`;
                }
                result += template;
                console.log(template);
              });
              search_result.innerHTML = result;
            }
          } else {
            if (value.length === 0) {
              search_result.innerHTML = '<p>' + (lang === 'fi') ? fi_text : en_text + '</p>';
            } else {
              search_result.innerHTML = '<p>' + (lang === 'fi') ? fi_no_found_text : en_no_found_text + '</p>';
            }
          }
        });
    }
  }, 400);

  (search) && search.forEach(el => {
    el.addEventListener('input', event => searchFn(event));
  });

});
